@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  progress[value] {
    @apply h-2 rounded-full;
    -webkit-appearance: none;
    appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    @apply rounded-full bg-slate-200 shadow-sm;
  }

  .red progress[value]::-webkit-progress-value {
    @apply rounded-full bg-slate-900;
  }

  .yellow progress[value]::-webkit-progress-value {
    @apply rounded-full bg-red-500;
  }

  .lightgreen progress[value]::-webkit-progress-value {
    @apply rounded-full bg-yellow-500;
  }

  .green progress[value]::-webkit-progress-value {
    @apply rounded-full bg-green-600;
  }

  .pagination {
    @apply flex items-center justify-center space-x-2;
  }

  .pagination .active {
    @apply rounded-full bg-gray-800 p-2 font-bold text-white;
  }
}
